import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { useUserAnalytics } from '../analytics/user';
import { IntakeFormEditor } from '../components/Event';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { useTitle } from '../hooks/useTitle';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw new Response('Not Found', { status: 404 });
  }

  const resp = await tokenWithRedirect(
    () => apiService.event.getIntakeEvent(id),
    action.request.url
  );

  return { event: resp.data.event };
};

export function Component() {
  const { event } = useLoaderData<typeof clientLoader>();
  useTitle(makeTitle(`Intake Form for ${event.orgName}`));
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
  ];

  return (
    <ProvidersList providers={providers}>
      <IntakeFormEditor event={event} />
    </ProvidersList>
  );
}
